import { Route, Routes, Navigate } from "react-router-dom";
import Main from "./components/Main";
import About from "./components/About";
import Signup from "./components/Singup";
import Login from "./components/Login";
import Contact from "./components/Contact";
import Navbar from './components/pages/Navbar';
import Footer from './components/pages/Footer';
import Admin from './components/Admin';

import AdminMessage  from "./components/AdminDashboard/ContactMessages";
import UserApproval  from "./components/AdminDashboard/UserApproval";
import InternshipFrom  from "./components/AdminDashboard/InternshipFrom";
import PlacementFrom  from "./components/AdminDashboard/PlacementForm";
import NewsFrom  from "./components/AdminDashboard/News";
import UserManagement  from "./components/AdminDashboard/UserManagement";
import Magement  from "./components/AdminDashboard/Magement";
import Internships from "./components/Internships";
import Placements from "./components/Placements";

import News from "./components/News";
// import AdminRouteGuard from './components/AdminRouteGuard'; 

function App() {
	const user = localStorage.getItem("token");
	const isAuthenticated = localStorage.getItem('token');
	const isAdmin = localStorage.getItem('isAdmin');
	const handleLogout = () => {
		localStorage.removeItem('token');
		localStorage.removeItem('isAdmin');
		window.location.reload();
	  };
	return (
		<div>
      <Navbar isAuthenticated={isAuthenticated} isAdmin={isAdmin} handleLogout={handleLogout} />
		<Routes>
			
			{/* {user && <Route path="/" exact element={<Main />} />} */}
			<Route path="/" exact element={<Main />} />
			<Route path="/about" exact element={<About />} />
			<Route path="/signup" exact element={<Signup />} />
			<Route path="/login" exact element={<Login />} />
			<Route path="/admin" exact element={<Admin />} />
			
			<Route path="/admin-message" element={<AdminMessage/>} />
			<Route path="/user-approval" element={<UserApproval/>} />
			<Route path="/internship-from" element={<InternshipFrom/>} />
			<Route path="/placement-from" element={<PlacementFrom/>} />
			<Route path="/news-From" element={<NewsFrom/>} />
			<Route path="/user-management" element={<UserManagement/>} />
			<Route path="/manage" element={<Magement/>} />
			{user && 
			<Route path="/internships" element={<Internships/>} />}
			
			{user && <Route path="/placements" element={<Placements/>} />}
			<Route path="/news" element={<News/>} />
		    
			<Route path="/contact" element={<Contact />} />
			<Route path="/" element={<Navigate replace to="/login" />} />
			
		</Routes>
		<Footer />
    </div>
		
	);
}

export default App;
